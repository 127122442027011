<template>
  <Dialog @close="$emit('close')" :title="'Cadastro'" :width="350" :height="660" :transition="'dialog-top-transition'">
    <v-card-text>
      <v-form v-model="form.valid" @keyup.enter.native="signUp">
        <v-text-field v-model="form.name.value"                 :rules="form.name.rules"                  :label="$t('Name')"              color='secondary' type="text"></v-text-field>
        <v-text-field v-model="form.email.value"                :rules="form.email.rules"                  label="Email"                   color='secondary' type="text"></v-text-field>
        <v-text-field v-model="form.password.value"             :rules="form.password.rules"              :label="$t('Password')"          color='secondary' type="password"></v-text-field>
        <v-text-field v-model="form.passwordConfirmation.value"                                           :label="$t('Confirm password')"  color='secondary' @keyup.enter.native="submit" type="password"
          :rules="[ form.passwordConfirmation.value.length === 0 ? 'Campo obrigatório' : form.passwordConfirmation.value !== form.password.value ? 'As senhas devem ser iguais' : true ]"
        ></v-text-field>
        <v-text-field v-model="form.phone.value"                                                          :label="$t('Phone')"             color='secondary' type="text" v-mask="'(##) ####-#####'"></v-text-field>
        <v-text-field v-model="form.birthday.value"             :rules="form.birthday.rules"              :label="$t('Birthday')"          color='secondary' v-mask="'##/##/####'"></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions class="d-flex flex-column mx-auto">
      <Button :disabled="!form.valid" class="sign-up mx-auto" color="secondary" :height="40" :loading="loading" @click="signUp">{{ $t('Sign Up') }}</Button>
      <div class="social-signup d-flex mx-auto justify-space-around mt-4">
        <Button class="mr-1" :color="'#1877f2'" :height="30" @click="socialSignUp('facebook')">
          <v-icon class="facebook-icon">mdi-facebook</v-icon><span class="ml-4">{{ `${$t('With the')} Facebook` }}</span>
        </Button>
        <Button class="ml-1" :color="'#ff8f00'" :height="30" @click="socialSignUp('google')">
          <v-icon class="google-icon">mdi-google</v-icon><span class="ml-3">{{ `${$t('With the')} Google` }}</span>
        </Button>
      </div>
    </v-card-actions>
    <span class="terms-and-policy">Cadastrando-se você concorda com os <a :href="termsUrl" target="_blank">Termos de Uso e Política de Privacidade</a></span>
    <!--<SnackBar v-if="snackbarMessage.length" :text="snackbarMessage" @close="snackbarMessage = ''"/>-->
    <!--<ErrorDialog v-if="error.length" :message="error" @close="error =''" />-->
  </Dialog>
</template>
<script>
export default {
  name: 'SignUp',
  components: {
    Button: () => import('@/components/ui/Button'),
    Dialog: () => import('@/components/ui/Dialog'),
  },
  data: () => ({
    form: {
      name: {
        value: '',
        rules: [ v => !!v || 'Campo obrigatório' ]
      },
      email: {
        value: '',
        rules: [
          v => !!v || 'Campo obrigatório',
          v => /.+@.+\..+/.test(v) || 'Formato do e-mail inválido',
        ]
      },
      password: {
        value: '',
        rules: [ 
          v => !!v || 'Campo obrigatório',
          v => v.length > 5 || "A senha deve conter ao menos 6 caracteres",
        ]
      },
      passwordConfirmation: {
        value: '',
      },
      phone: {
        value: '',
      },
      birthday: {
        value: '',
        rules: [v => !!v || 'Campo obrigatório']
      },
      valid: false
    },
    loading: false
  }),
  methods: {
    async signUp() {
      if (!this.form.valid) 
        return 

      this.loading = true
      await this.$store.dispatch('auth/signUp', {
        name: this.form.name.value,
        email: this.form.email.value,
        password: this.form.password.value,
        ...(this.form.phone.value.length && { phone: this.form.phone.value }),
        birthday: this.form.birthday.value
      })
      this.loading = false

      if (!this.$store.getters['auth/auth'].uid) 
        return

      this.close()
    },
    async socialSignUp (provider) { 
      this.loading = true
      await this.$store.dispatch(`auth/socialSingUpOrIn`, { provider, intent: 'signUp'})
      this.loading = false
      this.close()
    }, 
    close () {
      if (this.$store.getters['user/user']) 
        this.$emit('close')
    }
  },
  computed: {
    matchPassword () { return this.password.value === this.passwordConfirmation.value },
    termsUrl() { return `${window.location.origin}/terms-and-policy` }
  },
}
</script>
<style lang="scss" scoped>
  ::v-deep {
    .v-label, .reset-password, input {
      color: white !important;
    }
    .v-input__slot::before {
      border-color: white !important;
    }

    .error--text {
      color: #ffae00 !important;
      caret-color: #ffae00 !important;
    } 
  }

  .v-card__actions {
    button {
      &.sign-up { 
        font-size: 17px !important; 
        width: 100% !important; 
      }
    }
  }

  .social-signup {
    width: 100%;

    button {
      flex: 1;
      letter-spacing: 0;
      font-family: 'Nunito';

      span { 
        font-size: 12px; 
        color: white;
        text-shadow: 1px 1px 1px black;
      }

      .v-icon {
        position: absolute;
        left: -11px;

        &.facebook-icon { font-size: 21px; }
        &.google-icon { font-size: 15px; }
      }
    }
  }

  .terms-and-policy {
    display: block;
    font-size: 12px;
    color: white;
    margin: 2px 15px;
    
    a { 
      color: white; 
      text-decoration: underline;
    }
  }
</style>